import { AccordeonProduct, IsApp } from '../../models/utils';

export class UtilsState {
    app: IsApp;
    accordeonProduct: AccordeonProduct;
}

export enum UtilsActionsEnum {
    setIsApp = 'SET_IS_APP',
    setAccordeonProduct = 'SET_ACCORDEON_PRODUCT',
}

interface SetIsApp {
    type: typeof UtilsActionsEnum.setIsApp;
    payload: IsApp;
}

interface setAccordeonProduct {
    type: typeof UtilsActionsEnum.setAccordeonProduct;
    payload: AccordeonProduct;
}

export type UtilsActions = SetIsApp | setAccordeonProduct
