import { Logo } from './logo';

export enum SearchEntityTypesEnum {
    article = 'article',
    product = 'product',
}

export interface SearchArticleEntity {
    type: SearchEntityTypesEnum.article;
    searchEntity: {
        id: number;
        name: string;
        content: string;
        sefUrl: string;
        productName: string;
        productSefUrl: string;
        logoShowLink: string;
        sectionSefUrl: string;
    };
}

interface SearchDistinctProductLogo {
    createdAt: string;
    downloadLink: string;
    id: string;
    name: string;
    showLink: string;
    size: number;
}

export interface SearchDistinctProduct {
    id?: number;
    name?: string;
    code: string;
    selected?: boolean;
    logo?: SearchDistinctProductLogo | null;
}

export interface SearchDistinctSegment {
    id?: number;
    name?: string;
    products: SearchDistinctProduct[];
}

export interface QuickResponseModel {
    answer: string;
    sources: QuickResponseSource[];
}

export interface QuickResponseSource {
    url: string;
    title: string;
    productName: string;
    logo?: Logo;
}

export interface ArticleSearchResult {
    result: {
        articles: SearchArticleEntity[];
    };
    segments: SearchDistinctSegment[];
    distinctProducts: SearchDistinctProduct[];
    totalPages: number;
    totalItems: number;
    pageSize: number;
    page: number;
}
