import React, {
    FC,
    useEffect,
    useRef,
    useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SearchGeolocationModalStyles, ModalWrapper, StyledGeolocation } from './search-geolocation-modal.styles';
import { useDebounceHook } from '../../../custom-hooks/use-debounce.hook';
import { ProcessEnv } from '../../../helpers/env.helper';
import { getGeolocationCookieData, setGeolocation } from '../../../helpers/geolocation';
import { updEnv } from '../../../helpers/update-env';
import { GeoAnalytics } from '../../../helpers/web-analytics/geo-analytics';
import { WebAnalyticsActionGroupEnum, WebAnalyticsEventActionEnum, WebAnalyticsEventLabelsEnum } from '../../../models/enums/web-analytics.enum';
import { GeolocationData, RegionData } from '../../../models/geolocation';
import { setGeoModalOpened } from '../../../redux/ui/ui-actions';
import { selectGeoModalOpened } from '../../../redux/ui/ui-selectors';
import { GeolocationService } from '../../../services/geolocation.service';
import { CloseIcon } from '../../svg-components/main/close';
import { Accordion } from '../accordion/accordion';
import GeolocationFoundList from '../geolocation-found-list/geolocation-found-list';
import { SearchInput } from '../search-input/search-input';
import { SearchNotFound } from '../search-not-found/search-not-found';

interface SearchGeolocationModal {
    env: ProcessEnv;
}

export const SearchGeolocationModal: FC<SearchGeolocationModal> = ({ env }) => {
    updEnv(env);

    const dispatch = useDispatch();

    const [regions, setRegions] = useState<RegionData>();
    const [cities, setCities] = useState<Array<GeolocationData>>();
    const [foundGeolocation, setFoundGeolocation] = useState<Array<GeolocationData>>([]);
    const [regionId, setRegionId] = useState<number | null>(null);
    const [isOtherCities, setIsOtherCities] = useState<boolean>(false);
    const [loadingCities, setLoadingCities] = useState<boolean>(false);
    const [searchText, setTextValue] = useState('');
    const [isGeolocationSearchLoading, setGeolocationSearchLoading] = useState(false);
    const isOpen = useSelector(selectGeoModalOpened);
    const debouncedSearch = useDebounceHook(searchText, 500);
    const geolocation = getGeolocationCookieData();
    const abortController = useRef<AbortController | undefined>(undefined);
    const refCity = useRef<HTMLLIElement>(null);
    const refRegion = useRef<HTMLLIElement>(null);

    const otherCitiesClassName =
        isOtherCities && cities && cities[0].parent?.name === geolocation?.name ? 'active' : '';

    useEffect(() => {
        abortController.current = new AbortController();
        requestRegions();
        requestCities();
        return () => abortController.current?.abort();
    }, []);

    useEffect(() => {
        requestCities();
    }, [regionId]);

    useEffect(() => {
        requestGeolocation();
    }, [debouncedSearch]);

    useEffect(() => {
        if (debouncedSearch.length === 0 && foundGeolocation.length > 0) {
            setFoundGeolocation([]);
        }
    }, [foundGeolocation, debouncedSearch]);

    useEffect(() => {
        if (isOpen) {
            if (!regions || !cities) {
                requestRegions();
                requestCities();
            }
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isOpen]);

    useEffect(() => {
        const isRegion = Boolean(geolocation?.parent?.name);
        if (isRegion) {
            setIsOtherCities(false);
        } else {
            setIsOtherCities(true);
        }

        if (refCity && refCity.current) {
            refCity.current.scrollIntoView(false);
        }
        if (refRegion && refRegion.current) {
            refRegion.current.scrollIntoView(false);
        }
    }, []);

    const requestRegions = async () => {
        const regions = await GeolocationService.getRegions(abortController?.current);
        setRegions(regions);
    };

    const requestCities = async () => {
        const geolocationId = regionId || geolocation?.parent?.id || geolocation?.id;

        if (geolocationId) {
            setLoadingCities(true);
            const cities = await GeolocationService.getCityForRegion(geolocationId);
            setCities(cities);
            setLoadingCities(false);
        }
    };

    const requestGeolocation = async () => {
        if (debouncedSearch.length > 0) {
            try {
                setGeolocationSearchLoading(true);
                const result = await GeolocationService.geolocationSearch(debouncedSearch);
                setFoundGeolocation(result);
            } finally {
                setGeolocationSearchLoading(false);
            }
        }
    };

    const handleChangeId = (id: number | null) => {
        setRegionId(id);
    };

    const handleChangeGeolocation = async (geolocationData: GeolocationData) => {
        const changedRegionId = geolocationData.parent?.id;
        setGeolocation(geolocationData);
        setTextValue('');
        setIsOtherCities(false);
        if (changedRegionId) {
            setRegionId(changedRegionId);
        }
        GeoAnalytics.geoEvent(WebAnalyticsEventActionEnum.elementClick, WebAnalyticsEventLabelsEnum.region, WebAnalyticsActionGroupEnum.interactions, geolocationData.name);
        window.location.reload();
    };

    const handleChangeIsOtherCities = () => {
        if (cities) {
            const currentRegion = cities[0].parent;
            if (currentRegion) {
                setGeolocation(currentRegion);
                onCloseHandler();
                window.location.reload();
            }
        }
    };

    const handleClearInput = () => {
        setTextValue('');
        if (window && window.document) {
            window.document.getElementById('search-input')?.focus();
        }
    };

    const onCloseHandler = () => {
        dispatch(setGeoModalOpened(false));
    };

    return (
        <>
            {isOpen && regions && cities ? (
                <StyledGeolocation>
                    <div className="desktop-geolocation">
                        <ModalWrapper>
                            <div className="wrapper-content">
                                <SearchGeolocationModalStyles>
                                    <div className="title">Выберите ваше местоположение</div>
                                    <SearchInput
                                        placeholder="Поиск города или региона"
                                        className="search-input"
                                        value={searchText}
                                        onChange={(value) => setTextValue(value.replace(/[^ a-zа-яё\d]/iu, ''))}
                                        id="search-input"
                                    />
                                    {debouncedSearch.length > 1 && foundGeolocation?.length > 0 && (
                                        <GeolocationFoundList
                                            listSubject={foundGeolocation}
                                            onClick={handleChangeGeolocation}
                                        />
                                    )}
                                    {debouncedSearch.length > 1 && foundGeolocation?.length === 0 ? (
                                        <SearchNotFound changeQuery={handleClearInput} />
                                    ) : (
                                        <div className="content">
                                            <div className="regions">
                                                {Object.entries(regions || {}).map((item, index) => {
                                                    return (
                                                        <div className="block-region" key={index}>
                                                            <div className="letter">{item[0]}</div>
                                                            {item[1].map((region, key) => {
                                                                return (
                                                                    <ul key={key}>
                                                                        <li
                                                                            onClick={() => handleChangeId(region.id)}
                                                                            ref={
                                                                                geolocation?.parent?.name ===
                                                                                    region.name ||
                                                                                geolocation?.name === region.name
                                                                                    ? refRegion
                                                                                    : null
                                                                            }
                                                                        >
                                                                            {region.name}
                                                                        </li>
                                                                    </ul>
                                                                );
                                                            })}
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                            <div className="cities">
                                                <ul>
                                                    {cities?.map((item, key) => {
                                                        const activeClassName =
                                                            geolocation?.name === item.name && !isOtherCities
                                                                ? 'active'
                                                                : '';

                                                        return (
                                                            <li
                                                                key={key}
                                                                onClick={() => handleChangeGeolocation(item)}
                                                                ref={geolocation?.name === item.name ? refCity : null}
                                                                className={activeClassName}
                                                            >
                                                                {item.name}
                                                            </li>
                                                        );
                                                    })}
                                                    <li
                                                        onClick={handleChangeIsOtherCities}
                                                        ref={
                                                            isOtherCities &&
                                                            cities &&
                                                            cities[0].parent?.name === geolocation?.name
                                                                ? refCity
                                                                : null
                                                        }
                                                        className={otherCitiesClassName}
                                                    >
                                                        Другие города
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    )}
                                </SearchGeolocationModalStyles>
                                <CloseIcon onClick={onCloseHandler} className="close" />
                            </div>
                        </ModalWrapper>
                    </div>
                    <div className="mobile-geolocation">
                        <Accordion
                            regions={regions}
                            searchText={searchText}
                            onTextValue={(value) => setTextValue(value.replace(/[^ a-zа-яё\d]/iu, ''))}
                            getListCities={handleChangeId}
                            cities={cities}
                            onClose={onCloseHandler}
                            setGeolocation={handleChangeGeolocation}
                            debouncedSearch={debouncedSearch}
                            foundGeolocation={foundGeolocation}
                            setGeolocationFromSearch={handleChangeGeolocation}
                            setIsOtherCities={handleChangeIsOtherCities}
                            onQueryClear={() => setTextValue('')}
                            loadingCities={loadingCities}
                            geolocation={geolocation}
                            isGeolocationSearchLoading={isGeolocationSearchLoading}
                        />
                    </div>
                </StyledGeolocation>
            ) : null}
        </>
    );
};
