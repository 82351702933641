import { HYDRATE } from 'next-redux-wrapper';
import { ProductsState, SegmentActions, SegmentActionsEnum } from './segments-types';
import { HydrateAction } from '../hydrate-type';

const initialState = new ProductsState();

const segmentsReducer = (state = initialState, action: SegmentActions | HydrateAction) => {
    switch (action.type) {
        case SegmentActionsEnum.setSegment:
            return {
                ...state,
                segment: action.payload,
            };
        case SegmentActionsEnum.setCurrentChildSegment:
            return {
                ...state,
                currentChildSegment: action.payload,
            };
        case SegmentActionsEnum.setCurrentSegmentCode:
            return {
                ...state,
                currentSegmentCode: action.payload,
            };

        // необходимо указывать, какие поля надо получать с сервера, они будут приоритетом для стора при загрузке страницы
        // https://github.com/kirill-konshin/next-redux-wrapper#usage
        case HYDRATE:
            const hydrateData = action.payload.segment;
            return {
                ...state,
                segment: hydrateData.segment,
                currentSegmentCode: hydrateData.currentSegmentCode,
                currentChildSegment: hydrateData.currentChildSegment,
            };
        default:
            return state;
    }
};

export default segmentsReducer;
