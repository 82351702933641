import styled, { css } from 'styled-components';
import { headerHeight } from '../../../constants/header';
import { rem } from '../../../helpers/rem-converter';
import {
    desktopL,
    desktopS,
    desktopXS,
    media,
    mobile,
    tablet,
} from '../../../styles/themes/media';
import { Themeinterface } from '../../../styles/themes/theme.type';
import Variables from '../../../styles/variables';
import {
    sidePaddingsDesktop,
    sidePaddingsDesktopS,
    sidePaddingsDesktopXS,
    sidePaddingsMobile,
    sidePaddingsTablet,
} from '../../layout/container';

const geoStyles = css`
    padding-left: 0;
    margin-left: -4px;
`;

const HeaderContainer = styled.div<{ isVisible: boolean; isFixed: boolean; isShowHeader: boolean; theme: Themeinterface }>`
    display: flex;
    flex-direction: column;
    width: 100%;
    box-shadow: ${Variables.shadows.header};
    position: ${({ isShowHeader }) => isShowHeader ? 'fixed' : 'relative'};
    right: 0;
    left: 0;
    background-color: ${({ theme }) => theme.backgroundPrimaryElevated};
    z-index: ${({ isVisible }) => isVisible ? Variables.zIndexList.level5 : -1};
    top: ${({ isVisible }) => isVisible ? '0' : '-100px'};
    transition: top 0.4s ease-out, position 0.4s ease-out,  z-index 0.4s ease-out;

    .prp-header {
        position: static !important;
        box-shadow: none !important;
        transition: none !important;

        &__content {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }

        .prp-geo-line {
            .prp-geo-button {
                ${mobile`${geoStyles}`};
            }
        }

        .prp-menu__link {
            font-family: ${Variables.fonts.fontMedium};
        }

    }

    .prp-header-spacer {
        display: none;
    }
`;

const HeaderSpacer = styled.div`
    height: ${rem(headerHeight)};
    ${media(tablet, mobile)`
        height: ${rem(headerHeight)};
    `};
`;

const ModalWrapper = styled.div`
    position: relative;
    display: flex;
    justify-content: flex-end;
`;

const EcosystemHeaderNextWrapper = styled.div`
    padding: 0 ${rem(sidePaddingsMobile)};

    ${tablet`
        padding: 0 ${rem(sidePaddingsTablet)};
    `};

    ${desktopXS`
        width: calc(100% - (2 * ${rem(sidePaddingsDesktopXS)}));
        margin: 0 auto;
        padding: 0 ${rem(sidePaddingsDesktopXS)}
    `};

    ${desktopS`
        width: calc(100% - (2 * ${rem(sidePaddingsDesktopS)}));
        margin: 0 auto;
        padding: 0 ${rem(sidePaddingsDesktopS)}
    `};

    ${desktopL`
        max-width: ${rem(1168)};
        width: 100%;
        margin: 0 auto;
        padding: 0 ${rem(sidePaddingsDesktop)}
    `};
`;

export { HeaderContainer, ModalWrapper, HeaderSpacer, EcosystemHeaderNextWrapper };
