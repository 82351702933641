import { HYDRATE } from 'next-redux-wrapper';
import { UtilsActions, UtilsActionsEnum, UtilsState } from './utils-types';
import { HydrateAction } from '../hydrate-type';

const initialState = new UtilsState();

const utilsReducer = (state = initialState, action: UtilsActions| HydrateAction) => {
    switch (action.type) {
        case UtilsActionsEnum.setIsApp:
            return {
                ...state,
                app: action.payload,
            };
        case UtilsActionsEnum.setAccordeonProduct:
            return {
                ...state,
                accordeonProduct: {
                    ...state.accordeonProduct,
                    ...action.payload,
                },
            };
        case HYDRATE:
            const hydrateData = action.payload.utils;
            return {
                ...state,
                app: hydrateData.app,
            };
        default:
            return state;
    }
};

export default utilsReducer;
