import React, {
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';
import Link from 'next/link';
import { useSelector } from 'react-redux';
import * as Styled from './footer.styles';
import { useDevice } from '../../../context/device-context';
import { useIsApp } from '../../../custom-hooks/use-is-app';
import { ContactsPageAnalytics } from '../../../helpers/web-analytics/contacts-page-analytics';
import { selectCurrentSegmentCode } from '../../../redux/segments/segments-selectors';
import Container from '../../layout/container';

const Footer: FC = (): ReactElement => {
    const { isMobile } = useDevice();
    const [date, setDate] = useState<number>(2025);
    const { isApp } = useIsApp();
    const segmentCode = useSelector(selectCurrentSegmentCode);
    const contacts = segmentCode === 'individuals' ? { url: '/contacts', text: 'Контактный центр', target: '_self' } : { url: 'https://business.mts.ru/moskva/kontakty', text: 'Контактный центр для бизнеса', target: '_blank' };

    useEffect(() => {
        setDate(new Date().getFullYear());
    }, []);

    const contactsClick = () => {
        ContactsPageAnalytics.footerContactsClickEvent(contacts.text, contacts.url);
    };

    return (
        <Styled.FooterWrapper id="footer-id">
            {!isApp && (
                <Container>
                    {!isApp && (
                        <Styled.Footer id="footer-id">
                            {!isMobile && (
                                <Styled.FooterText>© {date} ПАО «МТС» Все права защищены</Styled.FooterText>
                            )}
                            <Link href={contacts.url} passHref >
                                <Styled.FooterLink target={contacts.target} onClick={contactsClick}>{contacts.text}</Styled.FooterLink>
                            </Link>
                            {isMobile && (
                                <Styled.FooterText>© {date} ПАО «МТС»<br/>Все права защищены. 18+</Styled.FooterText>
                            )}
                            {!isMobile && (
                                <Styled.FooterTextRight>18+</Styled.FooterTextRight>
                            )}
                        </Styled.Footer>
                    )}
                </Container>
            )}
        </Styled.FooterWrapper>
    );
};

export default Footer;
