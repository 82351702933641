import { Themeinterface } from './theme.type';
import errorPageImage from '../../images/error-page-light.png';
// @ts-ignore
import notFoundLightImage from '../../images/not-found-light.png';
import { themeEnum } from '../../models/enums/theme.enum';
import { color } from '../tokens/color';
import { colorConst } from '../tokens/new/color-const';
import Variables from '../variables';

export const lightTheme: Themeinterface = {
    themeName: themeEnum.light,
    brand: colorConst.torchRed,
    accentActive: colorConst.blueberryDark,
    accentPositive: colorConst.appleNormal,
    accentWarning: colorConst.bananaDark,
    accentNegative: colorConst.orangeNormal,
    accentActiveInverted: colorConst.blueberryLight,
    accentPositiveInverted: colorConst.appleLight,
    accentWarningInverted: colorConst.marigoldYellow,
    accentNegativeInverted: colorConst.salmon,
    textHeadline: colorConst.greyscale900,
    textPrimary: colorConst.greyscale800,
    textSecondary: colorConst.greyscale500,
    textTertiary: colorConst.greyscale400,
    textInverted: colorConst.alabaster,
    textPositive: colorConst.malachite,
    textNegative: colorConst.tiaMaria,
    textPrimaryLink: colorConst.scienceBlue,
    textPrimaryLinkHover: colorConst.blueberryDark,
    textPrimaryLinkVisited: colorConst.dodgerBlue,
    textPrimaryLinkInverted: colorConst.blueberryLight,
    textSecondaryLink: colorConst.greyscale500,
    textSecondaryLinkHover: colorConst.greyscale300,
    textSecondaryLinkVisited: colorConst.grayChateau,
    textVisitedLink: colorConst.plumNormal,
    textInactiveTabBar: colorConst.paleSky,
    backgroundPrimary: colorConst.greyscale0,
    backgroundStroke: colorConst.linkWater50,
    backgroundPrimaryElevated: colorConst.greyscale0,
    backgroundSecondary: colorConst.greyscale100,
    backgroundSecondaryElevated: colorConst.greyscale100,
    backgroundModal: colorConst.greyscale0,
    backgroundInverted: colorConst.greyscale800,
    backgroundOverlay: colorConst.cinder40,
    backgroundHover: colorConst.linkWater25,
    backgroundHoverInverted: colorConst.solitude20,
    backgroundNavbarIos: colorConst.white92,
    backgroundDisabled: colorConst.whisper,
    backgroundStrokeDisabled: colorConst.linkWater25,
    backgroundLower: colorConst.greyscale100,
    controlPrimaryActive: colorConst.torchRed,
    controlSecondaryActive: colorConst.greyscale800,
    controlTertiaryActive: colorConst.greyscale100,
    controlInactive: colorConst.linkWater50,
    controlAlternative: colorConst.greyscale0,
    controlAlternativeHover: colorConst.linkWater40,
    controlAlternativeStrokeHover: colorConst.solitude12,
    controlActiveTabBar: colorConst.torchRed,
    controlInactiveTabBar: colorConst.regentGray,
    controlStroke: colorConst.regentGray,
    iconPrimary: colorConst.greyscale800,
    iconSecondary: colorConst.regentGray,
    iconTertiary: colorConst.greyscale300,
    premium: 'linear-gradient(48deg, #9BD5FF 6.25%, #9B93FF 44.27%, #9A8DFF 58.33%, #7C86FE 84.9%)',
    blendHover: 'multiply',
    blendHoverInverted: 'normal',
    border: colorConst.linkWater50,
    loaderBackground: color.loaderBackground,
    loaderColor: 1,
    imageOpacity: 1,
    backgroundOverlayModal: colorConst.backgroundOverlayLight,
    sidebarContainerHide: Variables.sidebarContainerHide.standard,
    contentStroke: colorConst.linkWater50,
    notFoundImage: notFoundLightImage,
    footerBackground: colorConst.greyscale100,
    errorPageImage: errorPageImage,
    chipsHover: '#BCC3D066',
    chipsHoverActive: 'rgba(242, 243, 247, 0.12)',
    overlayAlternative: colorConst.cinder40,
};
