import {
    WebAnalyticsActionGroupEnum,
    WebAnalyticsEventActionEnum,
    WebAnalyticsEventCategoryEnum,
    WebAnalyticsEventEnum,
    WebAnalyticsEventLabelsEnum,
} from '../../models/enums/web-analytics.enum';
import { GtmService } from '../web-analytics.helpers';

export class ContactsPageAnalytics extends GtmService {
    static phoneClickEvent(phone: string) {
        this.pushEvent({
            event: WebAnalyticsEventEnum.vntContacts,
            eventCategory: WebAnalyticsEventCategoryEnum.contacts,
            eventAction: WebAnalyticsEventActionEnum.elementClick,
            eventLabel: WebAnalyticsEventLabelsEnum.phoneNumber,
            eventContent: phone,
            eventContext: null,
            filterName: null,
            actionGroup: WebAnalyticsActionGroupEnum.interactions,
            productName: null,
        });

        this.log(this.phoneClickEvent.name);
    }

    static goToSupportClickEvent() {
        this.pushEvent({
            event: WebAnalyticsEventEnum.vntContacts,
            eventCategory: WebAnalyticsEventCategoryEnum.contacts,
            eventAction: WebAnalyticsEventActionEnum.elementClick,
            eventLabel: WebAnalyticsEventLabelsEnum.support,
            eventContent: null,
            eventContext: null,
            filterName: null,
            actionGroup: WebAnalyticsActionGroupEnum.interactions,
            productName: null,
        });

        this.log(this.goToSupportClickEvent.name);
    }

    static goToMyMtsClickEvent() {
        this.pushEvent({
            event: WebAnalyticsEventEnum.vntContacts,
            eventCategory: WebAnalyticsEventCategoryEnum.contacts,
            eventAction: WebAnalyticsEventActionEnum.elementClick,
            eventLabel: WebAnalyticsEventLabelsEnum.moi_mts,
            eventContent: null,
            eventContext: null,
            filterName: null,
            actionGroup: WebAnalyticsActionGroupEnum.interactions,
            productName: null,
        });

        this.log(this.goToMyMtsClickEvent.name);
    }

    static footerContactsClickEvent(name: string, url: string) {
        this.pushEvent({
            event: WebAnalyticsEventEnum.vntFooter,
            eventCategory: WebAnalyticsEventCategoryEnum.footer,
            eventAction: WebAnalyticsEventActionEnum.linkClick,
            eventLabel: name,
            eventContent: url,
            eventContext: null,
            filterName: null,
            actionGroup: WebAnalyticsActionGroupEnum.interactions,
            productName: null,
        });

        this.log(this.footerContactsClickEvent.name);
    }

    static linkClickEvent(name: string, url: string) {
        this.pushEvent({
            event: WebAnalyticsEventEnum.vntContacts,
            eventCategory: WebAnalyticsEventCategoryEnum.contacts,
            eventAction: WebAnalyticsEventActionEnum.linkClick,
            eventLabel: name,
            eventContent: url,
            eventContext: null,
            filterName: null,
            actionGroup: WebAnalyticsActionGroupEnum.interactions,
            productName: null,
        });

        this.log(this.footerContactsClickEvent.name);
    }

    static accordeonClickEvent(type: string) {
        this.pushEvent({
            event: WebAnalyticsEventEnum.vntContacts,
            eventCategory: WebAnalyticsEventCategoryEnum.contacts,
            eventAction: WebAnalyticsEventActionEnum.elementClick,
            eventLabel: WebAnalyticsEventLabelsEnum.accordeon,
            eventContent: type,
            eventContext: null,
            filterName: null,
            actionGroup: WebAnalyticsActionGroupEnum.interactions,
            productName: null,
        });

        this.log(this.footerContactsClickEvent.name);
    }
}
