import React, { useEffect } from 'react';
import App, { AppContext, AppProps } from 'next/app';
import { Provider } from 'react-redux';
import { CookiesAccept } from '../components/containers/cookies-accept/cookies-accept';
import { GlobalLoader } from '../components/containers/global-loader/global-loader';
import { SearchGeolocationModal } from '../components/containers/search-geolocation-modal/search-geolocation-modal';
import { SeoForHead } from '../components/containers/seo-for-head/seo-for-head';
import { SnackbarsStack } from '../components/containers/snackbars-stack/snackbars-stack';
import RootLayout from '../components/layout/root-layout';
import { ChatMessenger } from '../components/sections/chat-messenger/chat-messenger';
import Footer from '../components/sections/footer/footer';
import Header from '../components/sections/header/header';
import Shell from '../components/shell.component';
import '@support-editor/mts-editor-dist/dist/style.css';
import { themeCookie } from '../constants/cookies';
import { ABTestContext } from '../context/ab-test-context';
import { DeviceContextProvider } from '../context/device-context';
import { FirstVisitContext } from '../context/first-visit-context';
import { ThemeContextProvider } from '../context/theme-context';
import { UserAgentContext } from '../context/user-agent-context';
import { useGtm } from '../custom-hooks/gtm-hook';
import { useBrowserSearch } from '../custom-hooks/use-browser-search.hook';
import { useClearSiteData } from '../custom-hooks/use-clear-site-data';
import useFirstVisit from '../custom-hooks/use-first-visit.hook';
import { useInitialProps } from '../custom-hooks/use-initial-props.hook';
import { useIsApp } from '../custom-hooks/use-is-app';
import { useScrollRestoration } from '../custom-hooks/use-scroll-restoration';
import { parseCookie, setCookie } from '../helpers/cookies';
import { deviceDetectHelper } from '../helpers/device-detect.helper';
import { getBrowserEnvs } from '../helpers/env.helper';
import { fixScrollIntoAnchor } from '../helpers/scroll-into-anchor';
import { userAgent } from '../helpers/user-agent';
import { contentSupport, title } from '../meta-tags';
import { ColorThemeEnum } from '../models/enums/color-theme.enum';
import { PagePropsModel } from '../models/page-props.model';
import { nextWrapper } from '../redux/store';
import { cookiesValidate } from '../repositories/cookies-validate.repository';
import { GlobalStyle } from '../styles/global/styled-global';
import { darkTheme } from '../styles/themes/dark';
import { lightTheme } from '../styles/themes/light';

fixScrollIntoAnchor();

const Application = ({ Component, ...rest }: AppProps) => {
    useInitialProps((rest?.pageProps as PagePropsModel)?.env);
    const { store, props } = nextWrapper.useWrappedStore(rest);
    const isFirstVisit = useFirstVisit();
    const { isApp } = useIsApp();

    const isDark = props.theme === ColorThemeEnum.dark;
    const colorTheme = isDark ? darkTheme : lightTheme;
    const themeName = isDark ? ColorThemeEnum.dark : ColorThemeEnum.light;

    useEffect(() => {
        // eslint-disable-next-line no-console
        console.log(`support app version: %c${process.env.APP_VERSION}`, 'color: #263238; font-weight: bold;');

        //@todo вернуть при работе с ab тестами
        /*if (props.utmData.isMyMtsApp) {
            const abTest = getCookie(abTestCookie);
            ABTestAnalytics.userGroup(process.env.APP_VERSION, props.abTest.main_search_help ? 1 : 0);

            if (!abTest) {
                setCookie(abTestCookie, JSON.stringify(props.abTest), 30);
            }
        }*/
        //@todo пока оставлю, на подумать на будущее, где может быть проблема в работе ютм на куках
        // if (props.utmData.isApp) {
        //     setCookie(isAppCookie, JSON.stringify(props.utmData), 1);
        // }
        setCookie(themeCookie, themeName);
    }, []);

    useGtm();
    useClearSiteData();
    useBrowserSearch();
    useScrollRestoration();

    return (
        <Provider store={store}>
            {/*todo: после рефакторинга поиска убрать UserAgentContext*/}
            <UserAgentContext.Provider value={userAgent}>
                <DeviceContextProvider value={props.deviceType}>
                    <ThemeContextProvider value={colorTheme}>
                        <SnackbarsStack/>
                        <CookiesAccept/>
                        <SeoForHead title={title} descriptionContent={contentSupport}/>
                        <GlobalStyle isApp={isApp}/>
                        <Shell>
                            <RootLayout>
                                <GlobalLoader/>
                                <ABTestContext.Provider value={props.abTest}>
                                    <FirstVisitContext.Provider value={isFirstVisit}>
                                        <SearchGeolocationModal env={props.env}/>
                                        <Header theme={themeName}/>
                                        <Component {...props.pageProps} />
                                        <ChatMessenger/>
                                    </FirstVisitContext.Provider>
                                </ABTestContext.Provider>
                                <Footer/>
                            </RootLayout>
                        </Shell>
                    </ThemeContextProvider>
                </DeviceContextProvider>
            </UserAgentContext.Provider>
        </Provider>
    );
};

Application.getInitialProps = async (context: AppContext) => {
    const appProps = await App.getInitialProps(context);
    const { query } = context.router;
    const cookies = parseCookie(context.ctx.req?.headers.cookie);
    const theme = query.theme || cookies[themeCookie];
    //@todo пока оставлю, на подумать на будущее, где может быть проблема в работе ютм на куках
    // const utmData = checkUtm(new URLSearchParams(query as Record<string, string>).toString(), cookies[isAppCookie]);
    // store.dispatch(setIsApp(utmData));

    //@todo вернуть при работе с ab тестами
    // const abTest = cookies[abTestCookie] ? JSON.parse(cookies[abTestCookie]) : utmData.isMyMtsApp && abTestHook();

    const userAgent = context.ctx.req?.headers['user-agent'] || '';
    const isMobile = deviceDetectHelper.isMobile(userAgent);
    const isTablet = deviceDetectHelper.isTablet(userAgent);
    const isDesktop = deviceDetectHelper.isDesktop(userAgent);
    const deviceType = isMobile ? 'mobile' : isTablet ? 'tablet' : isDesktop ? 'desktop' : 'mobile';

    const env = getBrowserEnvs(process.env);

    await cookiesValidate(context.ctx.req?.headers.cookie as string);

    return { ...appProps, theme, deviceType, env };
};

export default Application;
