export const title = 'МТС Поддержка';
export const titleB2BB2C = 'Поддержка МТС для бизнеса и физических лиц';
export const titleProductGroup = ' - Портфель продуктов';
export const titleProduct = ' - Продукты';
export const titleMobile = ' - Mobile';
export const titleSearch = 'Поиск';
export const titleArticle = ' - Статья';
export const titleContacts = 'Контактный центр';
export const titleContactsBusiness = 'Контактный центр для бизнеса и предпринимателей';
export const contentSupport = 'Помощь, инструкции и ответы на часто задаваемые вопросы от службы поддержки МТС';
export const contentProductGroup =
    'Помощь, инструкции и ответы на часто задаваемые вопросы от службы поддержки МТС';
export const contentProduct = '. Помощь, инструкции и ответы на часто задаваемые вопросы от службы поддержки МТС';
export const contentMobile = 'Mobile. Помощь, инструкции и ответы на часто задаваемые вопросы от службы поддержки МТС';
export const contentSearch = 'Поиск. Помощь, инструкции и ответы на часто задаваемые вопросы от службы поддержки МТС';
export const contentArticle = 'Помощь, инструкции и ответы на часто задаваемые вопросы от службы поддержки МТС';
export const contentContacts = 'Свяжитесь с оператором МТС через чат в приложении "Мой МТС" или в личном кабинете. Оперативное решение вопросов для физических и юридических лиц.';
export const contentContactsBusiness = 'Контактный центр МТС. Все номера телефонов поддержки экосистемы МТС для бизнеса и предпринимателей.';
export const itemSchemaService = 'https://schema.org/Service';
export const itemSchemaArticle = 'https://schema.org/Article';
export const itemSchemaSearchAction = 'https://schema.org/SearchAction';
export const itemSchemaBreadcrumbList = 'https://schema.org/BreadcrumbList';
export const itemSchemaFAQPage = 'https://schema.org/FAQPage';
export const itemSchemaQuestion = 'https://schema.org/Question';
export const itemSchemaAnswer = 'https://schema.org/Answer';
export const itemSchemaListItem = 'https://schema.org/ListItem';
