import { useEffect } from 'react';
import { useRouter } from 'next/router';

export const useScrollRestoration = () => {
    const router = useRouter();

    useEffect(() => {
        if ('scrollRestoration' in window.history) {
            window.history.scrollRestoration = 'manual';
        }

        const handleRouteChangeStart = () => {
            if (!router.asPath.includes('search') || !router.pathname.includes('article')) {
                sessionStorage.setItem(`scroll-${router.asPath}`, String(window.scrollY));
            }
        };

        const handleRouteChangeComplete = (url: string) => {
            const savedScroll = sessionStorage.getItem(`scroll-${url}`);
            const isBackSession = sessionStorage.getItem('scrollRestorationBack');

            if (isBackSession && savedScroll !== null && router.asPath === url) {
                window.scrollTo(0, parseInt(savedScroll, 10));
            }
            sessionStorage.removeItem('scrollRestorationBack');
        };

        const onPopState = () => {
            sessionStorage.setItem('scrollRestorationBack', 'true');
        };

        window.addEventListener('popstate', onPopState);
        router.events.on('routeChangeStart', handleRouteChangeStart);
        router.events.on('routeChangeComplete', handleRouteChangeComplete);

        return () => {
            window.removeEventListener('popstate', onPopState);
            router.events.off('routeChangeStart', handleRouteChangeStart);
            router.events.off('routeChangeComplete', handleRouteChangeComplete);
        };
    }, [router]);
};
