import { isProd } from './env.helper';
import {
    checkTouchPoint,
    getNumberType,
    transliterate,
    undefinedToNull,
} from './gtm.helper';
import { isServer } from './isServer';
import { logThis } from './log-this';
import { Article } from '../models/article';
import { CurrentUserModel } from '../models/current-user.model';
import { WebAnalyticsActionGroupEnum, WebAnalyticsEventEnum, WebAnalyticsNumberTypeEnum } from '../models/enums/web-analytics.enum';
import { Product } from '../models/product';
import { Nullable } from '../models/types/common.type';
import { logLevels } from '../models/types/logger.type';
import { WebAnalyticsDataInterface } from '../models/web-analytics.model';
import store from '../redux/store';
import { getProfile } from '../repositories/current-user.repository';

export type pushEventParams = Nullable<Partial<WebAnalyticsDataInterface>>;

const mockDataLayer = {
    push(gtmData) {
        // eslint-disable-next-line no-console
        console.log('GTM event pushed', gtmData);
    },
} as Nullable<WebAnalyticsDataInterface>[];

export class GtmService {
    static isDataLayerMocked = false;
    static user: CurrentUserModel | null = null;
    protected static userRequested = false;

    protected static get dataLayer(): Nullable<WebAnalyticsDataInterface>[] {
        if (isServer) {
            return [];
        }

        if (!isProd()) {
            GtmService.isDataLayerMocked = true;
            return mockDataLayer;
        }

        return isServer ? [] : window.dataLayer;
    }

    protected static get currentProduct(): Product {
        return store.getState().article?.currentProduct;
    }

    protected static get currentArticle(): Article {
        return store.getState().article?.currentArticle;
    }
    //@todo пока оставлю, на подумать на будущее, где может быть проблема в работе ютм на куках
    // protected static get touchPoint(): WebAnalyticsTouchPointEnum {
    //     return store.getState().utils.app.isApp ? WebAnalyticsTouchPointEnum.webview : WebAnalyticsTouchPointEnum.web;
    // }

    protected static get productName(): ReturnType<typeof transliterate> {
        return transliterate(this.currentProduct?.name, true);
    }

    protected static get articleName(): ReturnType<typeof transliterate> {
        return transliterate(this.currentArticle?.name, true);
    }

    static pushPage(eventData: {
        screenName: string;
        productName?: string | null;
        eventContent?: string | undefined;
    }) {
        this.push({
            event: WebAnalyticsEventEnum.scrn,
            actionGroup: WebAnalyticsActionGroupEnum.nonInteractions,
            ...eventData,
        });
    }

    static pushEvent(eventData: pushEventParams = {}) {
        this.push({
            event: WebAnalyticsEventEnum.vntSupport,
            actionGroup: WebAnalyticsActionGroupEnum.interactions,
            ...eventData,
        });
    }

    static async push(data = {}) {
        const user = await this.fetchUser();
        const userAuth = user ? 1 : 0;

        try {
            const screenName = isServer ? '' : window.location.pathname;

            const gtmObject: WebAnalyticsDataInterface = {
                // defaults
                userId: user?.id,
                userAuth,
                currentTariff: transliterate(user?.currentTariff) as string,
                numberType: getNumberType(user?.numberType) as WebAnalyticsNumberTypeEnum,
                accountType: transliterate(user?.accountType, true) as string,
                screenName,
                touchPoint: checkTouchPoint(),
                event: WebAnalyticsEventEnum.vntSupport,
                actionGroup: WebAnalyticsActionGroupEnum.interactions,
                abonent: undefined,
                eventContent: undefined,
                eventContext: undefined,
                //todo на всех событиях убрать кастомную отправку productName
                productName: GtmService.productName ? GtmService.productName : null,
                ...data,
            };

            if (GtmService.isDataLayerMocked) {
                this.dataLayer.push(undefinedToNull(gtmObject));
            } else {
                const timeForGtmLoad = 50;

                const dataLayerWaiter = () =>
                    setTimeout(() => {
                        if (!this.dataLayer) {
                            dataLayerWaiter();
                        } else {
                            this.dataLayer.push(undefinedToNull(gtmObject));
                        }
                    }, timeForGtmLoad);

                dataLayerWaiter();
            }
        } catch (e) {
            logThis('gtm.push', {
                level: logLevels.error,
                label: 'Ошибка gtm dataLayer.push',
                data: {
                    error: {
                        useId: user?.id,
                        ...e,
                    },
                    ...data,
                },
            });
        }
    }

    protected static async fetchUser() {
        if (!this.userRequested) {
            const profile = await getProfile();
            this.user = profile ? {
                id: profile['profile:guid'],
                name: profile['profile:name:org'],
                abonent: null,
                numberType: profile['profile:type'],
                accountType: profile['profile:type'],
                currentTariff: profile['mobile:tariff'],
                phone: profile['mobile:phone'],
                isAuth: 1,
            } : null;
            this.userRequested = true;
        }

        return this.user;
    }

    protected static log(name: string): void {
        if (!isProd()) {
            // eslint-disable-next-line no-console
            console.log('name', name);
        }
    }
}
